import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Website Content Writing Services",
  whatWeOffer: {
    leftPart: "Getting your website off the ground is no easy task. After you are finished with the design and all the functionalities are in order, you still need website content to complete the puzzle.",
    rightPart: "You need a team who knows how to work with developers and designers. It would help if you had someone who can understand your brand image, match your brand voice, and come up with content that fits and clearly delivers your message."
  },
  processText: "Our website content writing services are designed to do just that. Contact us to discuss further.",
  testimonials: testimonials
}

const meta = {
  title: "Website Content Writing Services",
  description: "Getting your website off the ground is no easy task. After you are finished with the design and all the functionalities are in order, you still need website content to complete the puzzle."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/website-content-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)